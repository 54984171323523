import React from 'react';
import { graphql } from 'gatsby';
import { PageMetadata } from '../components/structure/head';
import AdminInfo from '../components/structure/admin-info';
import Button from '../components/paragraphs/button';
import Link from '../components/elements/link';
import { getParagraph } from '../components/paragraphs/paragraphs';
import { InfoboxActivity } from '../components/paragraphs/information-box-activity';
import { InfoboxEvent } from '../components/paragraphs/information-box-event';
import { InfoboxSchoolActivity } from '../components/paragraphs/information-box-school-activity';
import { MailChimpOptInFormBlocks } from '../components/blocks/mailchimp-opt-in-form';
import { MessageSomePagesBlock } from '../components/blocks/message-some-pages';
import PageTitleSection from '../components/structure/page-header';
import { SiteWideMessageBlock } from '../components/blocks/site-wide-message';
import { TileImagesSomePagesBlocks } from '../components/blocks/tile-images-some-pages';
import { createPageParentTrail } from '../functions/build_menus/build-menu-tree';
import HeroImageSlide from '../components/structure/hero-image-slide';
import {
  testEnv,
  isUriExternal,
  hasDatePassed,
  stripUrlParkPrefix,
  randomKey,
  convertTrueFalseStringToBoolean,
} from '../functions/common';
import { DevAids } from '../components/dev-aids';
import Slider from 'react-slick';

export default function EventTemplate({ data, pageContext }) {
  const park = process.env.GATSBY_PARK,
    thisPage = data.nodeEvent,
    isLive = convertTrueFalseStringToBoolean(thisPage.status),
    state = thisPage.moderation_state,
    hideHeader = thisPage.field_hide_header_breadcrumbs,
    nodeID = thisPage.drupal_internal__nid,
    title = thisPage.title,
    path = pageContext?.alias || null,
    eventStartDate = thisPage.field_event_date?.value || null,
    eventEndDate = thisPage.field_event_date?.end_value || null,
    breadcrumbData = createPageParentTrail(pageContext.siteMenuData, nodeID, path) || null,
    tileImageURL =
      thisPage.relationships?.field_tile_image?.customLocalFieldMediaImage?.publicURL || null,
    // Get the raw HTML of the hero Block Title field, as this may contain HTML
    heroBlockTitle = thisPage.field_hero_block_title?.value || null,
    heroImages = thisPage.relationships.field_hero || [],
    paragraphs = thisPage.relationships.paragraphs.map((item) => getParagraph(item, pageContext)),
    infoboxData = thisPage.relationships.infobox || null,
    infoboxType =
      infoboxData?.paragraph_type?.drupal_internal__target_id.replace('information_', '') || null,
    parentPage =
      (breadcrumbData && stripUrlParkPrefix(breadcrumbData[1]?.node?.link?.uri_alias)) || null;

  // Hero image slider
  const sliderImages = heroImages.map((item, index: number) => (
    <HeroImageSlide
      node={item}
      slideCount={index}
      heroBlockTitle={heroBlockTitle}
      key={randomKey()}
    />
  ));

  const sliderSettings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    infinite: true,
    pauseOnFocus: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 1000,
  };

  // Assign the appropriate Infobox component based on the data provided
  // This is specific to the Infobox types permitted for this Content Type.
  // We do this here instead of in ./functions/common.js as it must return a JSX element
  // TODO: This can probably be refactored to use the same technique as getParagraphs()
  let assignInfobox = (infoboxData, infoboxType) => {
    switch (infoboxType) {
      case 'activity':
        return <InfoboxActivity {...infoboxData} />;
      case 'event':
        return <InfoboxEvent {...infoboxData} />;
      case 'school_activity':
        return <InfoboxSchoolActivity {...infoboxData} />;
      default:
        return false;
    }
  };

  const infobox = assignInfobox(infoboxData, infoboxType);

  // Check for the Book Now field and Eventbrite ID, and display a button if they exist
  const bookButton = () => {
    let link, title;
    if (thisPage.field_book_now === 'eventbrite' && thisPage.field_eventbrite_event_id) {
      (link = `https://www.eventbrite.com.au/e/${thisPage.field_eventbrite_event_id}`),
        (title = 'Visit the Eventbrite website');
    } else if (thisPage.field_book_now !== 'eventbrite' && isUriExternal(thisPage.field_book_now)) {
      link = thisPage.field_book_now;
    } else {
      testEnv().devMode
        ? console.warn(
            '[ISSUE - Content]: No valid link found for Book Now/Eventbrite fields for this Event'
          )
        : null;
      return;
    }
    return <Button url={link} title="Book online" style="default" />;
  };

  return (
    <>
      <PageMetadata
        title={title}
        description={thisPage.body?.summary || title}
        thumbnailImage={tileImageURL}
        path={path}
        dateModified={thisPage.changed}
      />

      {/* If not the homepage, add the title header and social links */}
      {path !== '' && hideHeader === false ? (
        <PageTitleSection
          title={title}
          socialMediaBlock={data.blockContentSocialMedia}
          breadcrumbs={breadcrumbData}
          summaryText={thisPage?.body?.summary || null}
        />
      ) : (
        ''
      )}

      {/* Slideshow */}
      {sliderImages.length > 0 ? (
        <div className="pb-xlarge">
          <Slider {...sliderSettings}>{sliderImages}</Slider>
        </div>
      ) : (
        <></>
      )}

      {/* Main content */}
      <main id="content">
        <div className="grid-wrapper">
          <div className="grid-row clearfix">
            <div className="grid-col grid-col--8 grid-col--push-2 tb-grid-col--10 tb-grid-col--push-1 ph-grid-col--12 ph-grid-col--push-0">
              <div className="copy">
                {/* Add a H1 for SEO if the banner is hidden */}
                {hideHeader && title && <h1 className="hide-visually">{title}</h1>}

                {/* Show an Edit link for Gatsby Preview environments */}
                <AdminInfo status={isLive} moderationState={state} path={path} />
                <DevAids node={thisPage} pageLinks={true} />
                <SiteWideMessageBlock
                  callingPark={park}
                  nodes={data.allBlockContentSiteWideMessage}
                />
                <MessageSomePagesBlock
                  nodeID={nodeID}
                  nodes={data.allBlockContentMessageSomePages}
                  callingPark={park}
                />
                {eventEndDate && hasDatePassed(eventEndDate) && (
                  <div className={`callout-box alert-box`}>
                    <p>
                      This event is over. Please visit our list of{' '}
                      <Link to={parentPage}>current events</Link>.
                    </p>
                  </div>
                )}
                {thisPage.body?.processed ? (
                  <div
                    className="body-content"
                    dangerouslySetInnerHTML={{
                      __html: thisPage.body?.processed,
                    }}
                  />
                ) : (
                  <></>
                )}
                {thisPage.field_book_now && bookButton()}
              </div>
            </div>
          </div>
        </div>

        {/* Paragraphs */}
        {paragraphs}
      </main>

      {/* Information box */}
      {infobox !== false && infobox}

      {/* Mailchimp Subscribe forms */}
      <MailChimpOptInFormBlocks
        nodes={data.allBlockContentMailchimpOptInForm}
        pagePath={pageContext.alias}
      />

      {/* Tile images on some pages Block */}
      <TileImagesSomePagesBlocks
        nodes={data.allBlockContentTileImagesSomePages}
        pagePath={pageContext.alias}
      />
    </>
  );
}

// This queries pages by their Drupal ID (UUID), not their Node ID
export const query = graphql`
  query ($park: String!, $drupalId: String!) {
    nodeEvent(drupal_id: { eq: $drupalId }) {
      id
      drupal_id
      drupal_internal__nid
      title
      created
      changed
      moderation_state
      status
      body {
        processed
        summary
      }
      path {
        alias
      }
      field_site {
        drupal_internal__target_id
      }
      field_book_now
      field_eventbrite_event_id
      field_event_date {
        end_value
        value
      }
      field_hero_block_title {
        value
      }
      field_legacy_url
      field_hide_header_breadcrumbs
      relationships {
        field_hero {
          ...MediaDataHeroImage
          relationships {
            field_media_image {
              uri {
                url
              }
            }
          }
        }
        field_tile_image {
          ...MediaDataTileImage
          field_media_image {
            alt
            title
            drupal_internal__target_id
          }
        }
        paragraphs: field_paragraphs {
          type: __typename
          ...ParagraphAccordionContainer
          ...ParagraphButtonsContainer
          ...ParagraphDownloadsList
          # ...ParagraphEmbedIframe
          # ...ParagraphEmbedMap
          # ...ParagraphEmbedScript
          ...ParagraphEmbedVideo
          ...ParagraphFeedList
          ...ParagraphFigure
          ...ParagraphGalleryGrid
          ...ParagraphHeroImageSection
          ...ParagraphQuoteWithImage
          # ...ParagraphSectionHeadingLinks
          ...ParagraphPeople
          ...ParagraphText
          ...ParagraphTileImages
        }
        infobox: field_information_widget {
          ...InformationBoxActivity
          ...InformationBoxEvent
          ...InformationBoxSchoolActivity
        }
      }
    }
    allBlockContentSiteWideMessage(
      filter: {
        field_site_for_block: { drupal_internal__target_id: { eq: $park } }
        field_enabled: { eq: true }
      }
    ) {
      ...SiteWideMessageBlockQuery
    }
    allBlockContentMessageSomePages(
      filter: {
        field_site_for_block: { drupal_internal__target_id: { eq: $park } }
        field_enabled: { eq: true }
      }
    ) {
      ...MessageSomePagesBlockQuery
    }
    allBlockContentTileImagesSomePages(
      filter: {
        field_site_for_block: { drupal_internal__target_id: { eq: $park } }
        field_enabled: { eq: true }
      }
    ) {
      ...TileImagesSomePagesBlockQuery
    }
    blockContentSocialMedia {
      ...SocialMediaLinksBlockQuery
    }
    allBlockContentMailchimpOptInForm(
      filter: {
        field_site_for_block: { drupal_internal__target_id: { eq: $park } }
        field_enabled: { eq: true }
      }
    ) {
      ...MailChimpOptInFormBlockQuery
    }
  }
`;
